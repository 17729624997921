import React, { useCallback, useEffect, useState } from "react";
import AdicionarDocumentoManualDialog from "./AdicionarDocumentoManualDialog";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentoManual } from "../../selectors/workflow.selectors";
import { actions } from "../../actions/workflow.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";

const AdicionarDocumentoManualDialogConnected = () => {
  const dispatch = useDispatch();
  const {
    entrada,
    loading,
    show,
    submitting,
    dominios: { referencias, documentos: todosDocumentos },
    default: { documento: documentoPadrao, referencia: referenciaPadrao },
    aberturaManual,
    callback
  } = useSelector(getDocumentoManual);
  const [documentos, setDocumentos] = useState([]);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirDocumentoManual({ show: false }));
  }, [dispatch]);

  const initialValues = useCallback(
    () => ({
      referencia: "",
      documento: null,
      comentario: "",
    }),
    []
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: useCallback(
      () => ({
        referencia: validators.string({ required: true }),
        documento: validators.object({ required: true }),
      }),
      []
    ),
    initialValues,
    handleSubmitWithForm: useCallback(
      (form) => {
        const callbackLocal = () => {
          form.resetField("documento");
        };

        return (values) => {
          if (entrada.tipo === "parceiro") {
            dispatch(
              actions.gerarDocumentoParceiroManual.request({
                ...entrada,
                ...values,
                handleClose,
                callback: entrada.callback ?? callbackLocal,
              })
            );
          } else {
            dispatch(
              actions.gerarDocumentoOperacaoManual.request({
                ...entrada,
                ...values,
                aberturaManual,
                handleClose,
                callback: entrada.callback ?? callbackLocal,
              })
            );
          }
        };
      },
      [dispatch, entrada]
    ),
  });

  const handleReferenciaChange = useCallback(
    (e) => {
      const [, tipo] = e.target.value.split("|");

      switch (tipo) {
        case "IM":
        case "OP":
        case "PA":
          setDocumentos(todosDocumentos.filter((d) => d.referencia === tipo));
          break;
        case "CO":
        case "CC":
        case "VD":
        case "CV":
          setDocumentos(todosDocumentos.filter((d) => d.referencia === "PS"));
          break;
        default:
          setDocumentos([]);
          break;
      }
    },
    [todosDocumentos, setDocumentos]
  );

  const { reset } = formProps;

  useEffect(() => {
    formProps.setValue('referencia', referenciaPadrao);

    if (todosDocumentos.length > 0 && !!referenciaPadrao) {
      handleReferenciaChange({ target: { value: referenciaPadrao } })
      formProps.setValue('documento', todosDocumentos.find(d => d.id === documentoPadrao));
    }
  }, [documentoPadrao, todosDocumentos, referenciaPadrao, referencias]);

  useEffect(() => {
    reset(initialValues());
  }, [show, reset, initialValues]);

  return (
    <AdicionarDocumentoManualDialog
      open={show}
      referencias={referencias}
      documentos={documentos}
      loading={loading}
      formProps={formProps}
      handleReferenciaChange={handleReferenciaChange}
      handleSubmit={handleSubmit}
      submitting={submitting}
      handleClose={handleClose}
    />
  );
};

export default AdicionarDocumentoManualDialogConnected;
