import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpload } from "../../selectors/documentos.selectors";
import UploadDialog from "./UploadDialog";
import { toast } from "react-toastify";
import { actions } from "../../actions/operacao.actions";
import { actions as documentoActions } from "../../actions/documentos.actions";
import { isSubindoArquivos } from "../../selectors/operacao.selectors";

const UploadDialogConnected = () => {
  const dispatch = useDispatch();
  const uploading = useSelector(isSubindoArquivos);
  const { show, data } = useSelector(getUpload);

  const operacao = data?.operacao;

  const handleUpload = useCallback(
    ({ id, padrao, arquivos }, ok, nok) => {
      if (nok.length > 0) {
        toast.error("");
      } else {
        switch (padrao) {
          case "VC":
            if (arquivos.length > 0) {
              dispatch(actions.uploadDocumento.request({ id, files: ok }));
            } else {
              dispatch(
                documentoActions.exibirValidadeCredito(true, {
                  id,
                  files: ok,
                  operacao,
                })
              );
            }
            break;
          case "LA":
            if (arquivos.length > 0) {
              dispatch(actions.uploadDocumento.request({ id, files: ok }));
            } else {
              dispatch(
                documentoActions.exibirLaudoAvaliacao(true, {
                  id,
                  files: ok,
                  operacao,
                })
              );
            }
            break;
          case "ES":
            if (arquivos.length > 0) {
              dispatch(actions.uploadDocumento.request({ id, files: ok }));
            } else {
              dispatch(
                documentoActions.exibirCondicoesComerciais(true, {
                  id,
                  files: ok,
                  operacao,
                })
              );
            }
            break;
          case "IA":
            dispatch(
              documentoActions.exibirIA(true, {
                id,
                operacao,
                files: ok,
              })
            );
            break;
          default:
            dispatch(actions.uploadDocumento.request({ id, files: ok }));
            break;
        }
      }
    },
    [dispatch, operacao]
  );

  const handleClose = useCallback(() => {
    dispatch(documentoActions.exibirUpload(false, null));
  }, [dispatch]);

  return (
    <UploadDialog
      open={show}
      data={data}
      handleClose={handleClose}
      handleUpload={handleUpload}
      uploading={uploading}
    />
  );
};

export default UploadDialogConnected;