import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isBaixandoArquivos } from "../../../selectors/operacao.selectors";
import useCompleteForm from "../../../hooks/useCompleteForm";
import validators from "../../../utils/validators";
import { actions } from "../../../actions/documentos.actions";
import { actions as operacaoActions } from "../../../actions/operacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../actions/rotas.actions";
import {
  getDominiosOperacao,
  getValidacaoDocumento,
} from "../../../selectors/documentos.selectors";
import ValidacaoDocumentoForm from "./ValidacaoDocumentoForm";
import { actions as workflowActions } from "../../../actions/workflow.actions";

const avaliarCondicao = (questao, values) => {
  if (!questao.condicao) {
    return true;
  }

  const check = new Function("questao", "values", questao.condicao);
  return check(questao, values);
};

const avaliarValidacaoEspecial = (condition) => {
  if (!condition) {
    return false;
  }

  const check = new Function("value", condition);
  return {
    validation: check,
  };
};

const ValidacaoDocumentoFormConnected = () => {
  const dispatch = useDispatch();
  const { loading, submitting, erros, documento } = useSelector(
    getValidacaoDocumento
  );
  const downloading = useSelector(isBaixandoArquivos);

  const [questoes, setQuestoes] = useState(documento?.questoes);
  const [visible, setVisible] = useState([]);

  const isCompleta = useCallback(
    (values) => {
      let result = true;

      const questoesVisiveis = questoes.filter(
        (q, i) => visible[i] && ["TI", "ST", "DS"].indexOf(q.tipoCampo) === -1
      );

      for (const q of questoesVisiveis) {
        result &= !!values[q.codigo];
      }

      return result;
    },
    [questoes, visible]
  );

  const validatorsTypes = (tipoDominio) => {
    switch (tipoDominio) {
      case "CP":
        return "cpfCnpj";
      case "PH":
        return "phone";
      case "DT":
        return "date";
      case "IN":
        return "number";
      case "OP":
      case "UF":
        return "object";
      default:
        return "string";
    }
  };
  const initialValueTypes = (tipoCampo, q) => {
    switch (tipoCampo) {
      case "TX":
        return "";
      case "SE":
      case "AC":
        return [];
      case "RB":
      case "CX":
      default:
        return null;
    }
  };

  const processarResposta = useCallback((questao) => {
    switch (questao.tipoCampo) {
      case "AC":
        if (questao.multiple) {
          return questao?.resposta?.split(",").map(r => questao?.opcoes?.find(o => o?.codigo === r));
        } else {
          return questao?.opcoes?.find(o => o?.codigo === questao?.resposta)
        }

      default:
        return questao?.resposta
    }
  }, [documento])

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () =>
      documento?.questoes.reduce((acc, q) => {
        const key = q.codigo;
        if (key !== "undefined") {
          acc[key] = validators[validatorsTypes(q.tipoDominio)]({
            required: q.obrigatorio,
            custom: avaliarValidacaoEspecial(q.validacaoEspecial),
          });
        }
        return acc;
      }),
    initialValues: useCallback(
      () => ({
        ...documento?.questoes?.reduce(
          (r, q) => ({
            ...r,
            [q.codigo]: processarResposta(q) ?? q.valorDefault ?? initialValueTypes(q.tipoCampo, q),
          }),
          {}
        ),
      }),
      [documento]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions[
            documento.status === "AP" || documento.status === "RE"
              ? "confirmarValidacaoDocumento"
              : "gravarValidacaoDocumento"
          ].request({
            callback: handleGoBack,
            documentoOperacao: {
              ...documento,
              questoes: questoes.map((q, i) => ({
                idItemFormulario: q.id,
                itemFormulario: { tipoCampo: q.tipoCampo },
                resposta: visible[i] ? processarValor(values[q.codigo]) : null,
              })),
            },
          })
        );
      },
      [dispatch, isCompleta, visible, documento.status]
    ),
  });

  const processarValor = (value) => {
    if (value instanceof Array) {
      return value.map(v => v.codigo).join(",");
    } else if (value instanceof Object) {
      return value.codigo;
    }
    return value;
  }

  const updateVisible = useCallback(
    (values) => {
      setVisible(questoes.map((q) => avaliarCondicao(q, values)));
    },
    [questoes]
  );

  useEffect(() => {
    updateVisible(formProps.getValues());
  }, [questoes]);

  const handleDownload = useCallback(
    ({ tipo, id }) => {
      dispatch(operacaoActions.downloadDocumento.request({ tipo, id }));
    },
    [dispatch]
  );

  const handleGoBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, {
        id: documento.idOperacao,
      })
    );
  }, [dispatch]);

  const handleAddDocument = useCallback(
    (e, chk, aberturaManual, d, r) => {
      e.stopPropagation();
      dispatch(
        workflowActions.exibirDocumentoManual({
          show: true,
          id: documento.idOperacao,
          tipo: "operacao",
          checklist: chk,
          aberturaManual,
          documento: d,
          referencia: r,
          callback: ({ handleClose }) => {
            handleClose();
            dispatch(actions.confirmarValidacaoDocumento.request({
              documentoOperacao: documento,
              statusAnalise: "RE",
              callback: handleGoBack
            }))
          }
        })
      );
    },
    [dispatch, documento]
  );

  return (
    <ValidacaoDocumentoForm
      data={documento}
      erros={erros}
      loading={loading}
      values={formProps.getValues()}
      handleSubmit={handleSubmit}
      handleDownload={handleDownload}
      downloading={downloading}
      submitting={submitting}
      formProps={formProps}
      questoes={questoes}
      visible={visible}
      updateVisible={updateVisible}
      handleGoBack={handleGoBack}
      handleAddDocument={handleAddDocument}
    />
  );
};

export default ValidacaoDocumentoFormConnected;
