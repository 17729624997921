import {
  Grid,
  IconButton,
  styled,
  DialogContent,
  Dialog,
  keyframes
} from "@mui/material";
import React, {useEffect, useState} from "react";
import { Button, Title, Tooltip } from "../../components";
import Dropzone from "react-dropzone";
import {
  AddBoxOutlined,
  CloseSharp,
  CloudUpload,
  DeleteForever,
} from "@mui/icons-material";
import { transientOptions } from "../../styles/theme";
import iconePdf from "../../assets/pdf.png";
import iconeImagem from "../../assets/imagem.png";
import iconeArquivo from "../../assets/outros.png";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const borderAnimation = keyframes`
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
`

const DropZoneArea = styled('div')`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 99%;
  height: 200px;
  animation-name: ${borderAnimation};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background-image: linear-gradient(90deg, ${({ theme }) => theme.colors["secondary"]._300} 50%, transparent 50%), linear-gradient(90deg, ${({ theme }) => theme.colors["secondary"]._300} 50%, transparent 50%), linear-gradient(0deg, ${({ theme }) => theme.colors["secondary"]._300} 50%, transparent 50%), linear-gradient(0deg, ${({ theme }) => theme.colors["secondary"]._300} 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation-play-state: ${({ theme, isDragAccept }) => !!isDragAccept ? "running" : "paused" };
  cursor: pointer;
`;

const StyledDropZoneText = styled("h4", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-weight: 400;
  display: ${({ _display_ = "block" }) => _display_};
  text-align: ${({ _align_ = "left" }) => _align_};
  color: ${({ theme }) => theme.colors["secondary"]._300};
  margin: ${({ _margin_ = "0" }) => _margin_};
`;

const StyledUploadIcon =  styled(CloudUpload)`
  fill:${({ theme }) => theme.colors["secondary"]._300};
`
const FilesArea  = styled('div')`
  display:grid;
  grid-template-columns: repeat(4 ,1fr) ;
  padding: 1rem 0;
  gap:1rem;
`
const AddFileIcon = styled(AddBoxOutlined)`
  margin: 6px;
  fill:${({ theme }) => theme.colors["secondary"]._200};
`

const ContainerArquivoTemporario = styled(Grid, transientOptions)`
  background-color: ${({ theme, _color_ = "primary" }) =>
  theme.colors[_color_]._50};
  border: 1px dashed
    ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._200};
  border-radius: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._700};
  
  &:hover{
    background-color: ${({ theme, _color_ = "primary" }) =>
            theme.colors["error"]._50};
    border: 1px dashed
    ${({ theme, _color_ = "primary" }) => theme.colors["error"]._200};
  }
  
`;
const ContainerAddArquivoTemporario = styled(Grid, transientOptions)`
  background-color: ${({ theme, _color_ = "primary" }) =>
  theme.colors[_color_]._50};
  border: 1px dashed
    ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._200};
  border-radius: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._700};

  &:hover{
    svg{
      fill: ${({ theme }) => theme.colors["primary"]._500};
    }
  }
`;
const NomeArquivo = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: block;
  color: ${({ theme }) => theme.colors.grey._400};
`;
const TipoArquivo = styled("img")`
  width: 32px;
  height: auto;
`;

const getTipoArquivo = (tipo) => {
  if (tipo.indexOf("pdf") >= 0) {
    return iconePdf;
  }
  if (tipo.indexOf("image") >= 0) {
    return iconeImagem;
  }
  return iconeArquivo;
};

const StyledIconButton = styled(IconButton)`
  color: inherit;
`;

const DropZoneDialog = ({
  data,
  isOpen,
  handleClose,
  handleUpload,
  uploading,
}) => {
  const [files, setFiles] = useState([]);
  const [uploadingFlag, setUploadingFlag] = useState(false);

  useEffect(() => {
    if (uploadingFlag && uploading.indexOf(data?.id) < 0) {
      setUploadingFlag(false);
      handleClose();
      setFiles([]);
    }
  }, [uploadingFlag, uploading, handleClose, data?.id]);

  const handleUploadLocal = (ok, nok) => {
    const newOk = ok.filter((f) => !files.find((a) => f.name === a.name));
    const newFiles = [...files, ...newOk];
    setFiles(newFiles);
  };

  const deleteFile = (f) => {
    const newFiles = files.filter((a) => f.name !== a.name);
    setFiles(newFiles);
  };

  return (
    <StyledDialog
      onClose={() => {
        handleClose();
        setFiles([]);
      }}
      disableEscapeKeyDown
      maxWidth="sm"
      open={isOpen}
    >
      <DialogContent>
        <CloseIconButton
          onClick={() => {
            handleClose();
            setFiles([]);
          }}
        >
          <CloseSharp />
        </CloseIconButton>
        <Title as={"h2"} _margin_={"0 0 1rem 0"}>
          Adicionar Arquivos
        </Title>
        <Dropzone
          onDrop={(acceptedFiles, fileRejections) =>
            handleUploadLocal(acceptedFiles, fileRejections)
          }
        >
          {({ getRootProps, getInputProps, isDragAccept, isFocused }) => (
            <>
              <DropZoneArea {...getRootProps({ isDragAccept })}>
                <input {...getInputProps()} />
                <StyledDropZoneText>
                  Arraste e solte arquivos aqui para adicioná-los.
                </StyledDropZoneText>
                <StyledUploadIcon fontSize={"large"} />
              </DropZoneArea>
              <FilesArea>
                {files.map((file, index) => (
                  <ContainerArquivoTemporario key={`${file.name}${index}`} item>
                    <Grid
                      container
                      columnSpacing={1}
                      alignItems={"center"}
                      flexDirection={"row"}
                    >
                      <Grid item>
                        <TipoArquivo src={getTipoArquivo(file.type)} />
                      </Grid>
                      <Grid item flex={1}>
                        <NomeArquivo>{file.name}</NomeArquivo>
                      </Grid>
                      <Grid item>
                        <Tooltip title={"Excluir arquivo"} arrow>
                          <StyledIconButton
                            onClick={() =>
                              uploading.indexOf(data.id) < 0 && deleteFile(file)
                            }
                          >
                            <DeleteForever />
                          </StyledIconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </ContainerArquivoTemporario>
                ))}
                <ContainerAddArquivoTemporario
                  {...getRootProps()}
                  item
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid
                    container
                    columnSpacing={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <AddFileIcon />
                  </Grid>
                </ContainerAddArquivoTemporario>
              </FilesArea>
            </>
          )}
        </Dropzone>
        <Grid item container justifyContent={"center"} mt={1}>
          <Grid item xs={12} md={8}>
            <Button
              onClick={() => {
                handleUpload(data, files, []);
                setUploadingFlag(true);
              }}
              disabled={files.length === 0}
              fullWidth
              loading={uploading.indexOf(data?.id) >= 0}
            >
              ADICIONAR ARQUIVOS
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

const UploadDialog = ({ handleClose, open, data, handleUpload, uploading }) => {
  return (
    <DropZoneDialog
      data={data}
      isOpen={open}
      handleClose={handleClose}
      handleUpload={handleUpload}
      uploading={uploading}
    />
  );
};

export default UploadDialog;