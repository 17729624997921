import React, { useCallback } from "react";
import InteligenciaArtificialDocumentoDialog from "./InteligenciaArtificialDocumentoDialog";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/documentos.actions";
import { getIA } from "../../selectors/documentos.selectors";

const InteligenciaArtificialDocumentoDialogConnected = () => {
  const dispatch = useDispatch();
  const { show, submitting, validating, result, data } = useSelector(getIA);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirIA(false, null));
  }, [dispatch]);

  const handleContinuar = useCallback(() => {
    dispatch(actions.exibirIA(false, null));
    if (result.situacao == "FI" && result.erros.length > 0) {
      dispatch(actions.exibirUpload(true, data));
    }
  }, [dispatch, handleClose, result]);

  return (
    <InteligenciaArtificialDocumentoDialog
      open={show}
      validating={validating}
      result={result}
      submitting={submitting}
      handleContinuar={handleContinuar}
      handleClose={handleClose}
    />
  );
};

export default InteligenciaArtificialDocumentoDialogConnected;
