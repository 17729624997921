import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  DOMINIO_OPERACAO: "@@documentos/DOMINIO_OPERACAO",

  EXIBIR_CONDICOES_COMERCIAIS: "@@documentos/EXIBIR_CONDICOES_COMERCIAIS",
  GRAVAR_CONDICOES_COMERCIAIS: "@@documentos/GRAVAR_CONDICOES_COMERCIAIS",

  EXIBIR_VALIDACAO_DOCUMENTO: "@@documentos/EXIBIR_VALIDACAO_DOCUMENTO",
  GRAVAR_VALIDACAO_DOCUMENTO: "@@documentos/GRAVAR_VALIDACAO_DOCUMENTO",
  CONFIRMAR_VALIDACAO_DOCUMENTO: "@@documentos/CONFIRMAR_VALIDACAO_DOCUMENTO",

  EXIBIR_VALIDADE_CREDITO: "@@documentos/EXIBIR_VALIDADE_CREDITO",
  GRAVAR_VALIDADE_CREDITO: "@@documentos/GRAVAR_VALIDADE_CREDITO",

  EXIBIR_LAUDO_AVALIACAO: "@@documentos/EXIBIR_LAUDO_AVALIACAO",
  GRAVAR_LAUDO_AVALIACAO: "@@documentos/GRAVAR_LAUDO_AVALIACAO",

  PERMITE_ENVIAR_DOCUMENTOS: "@@documentos/PERMITE_ENVIAR_DOCUMENTOS",
  PERMITE_ENVIAR_DOCUMENTO: "@@documentos/PERMITE_ENVIAR_DOCUMENTO",
  EXCLUIR_DOCUMENTO: "@@documentos/EXCLUIR_DOCUMENTO",

  OBTER_RETORNO_ANALISE: "@@documentos/OBTER_RETORNO_ANALISE",

  EXIBIR_IA: "@@documentos/EXIBIR_IA",
  VALIDAR_IA: "@@documentos/VALIDAR_IA",
  GRAVAR_IA: "@@documentos/GRAVAR_IA",

  EXIBIR_UPLOAD: "@@documentos/EXIBIR_UPLOAD", 
};

export const actions = {
  dominioOperacao: createAsyncAction(types.DOMINIO_OPERACAO),

  exibirValidadeCredito: (show, data) =>
    createSyncAction(types.EXIBIR_VALIDADE_CREDITO, { show, data }),
  gravarValidadeCredito: createAsyncAction(types.GRAVAR_VALIDADE_CREDITO),

  exibirValidacaoDocumento: createAsyncAction(types.EXIBIR_VALIDACAO_DOCUMENTO),
  gravarValidacaoDocumento: createAsyncAction(types.GRAVAR_VALIDACAO_DOCUMENTO),
  confirmarValidacaoDocumento: createAsyncAction(types.CONFIRMAR_VALIDACAO_DOCUMENTO),

  exibirCondicoesComerciais: (show, data) =>
    createSyncAction(types.EXIBIR_CONDICOES_COMERCIAIS, { show, data }),
  gravarCondicoesComerciais: createAsyncAction(
    types.GRAVAR_CONDICOES_COMERCIAIS
  ),

  excluirDocumento: createAsyncAction(types.EXCLUIR_DOCUMENTO),
  exibirLaudoAvaliacao: (show, data) =>
    createSyncAction(types.EXIBIR_LAUDO_AVALIACAO, { show, data }),
  gravarLaudoAvaliacao: createAsyncAction(types.GRAVAR_LAUDO_AVALIACAO),

  permiteEnviarDocumentos: createAsyncAction(types.PERMITE_ENVIAR_DOCUMENTOS),
  permiteEnviarDocumento: createAsyncAction(types.PERMITE_ENVIAR_DOCUMENTO),

  obterRetornoAnalise: createAsyncAction(types.OBTER_RETORNO_ANALISE),

  exibirIA: (show, data) => createSyncAction(types.EXIBIR_IA, { show, data }),
  validarIA: createAsyncAction(types.VALIDAR_IA),
  gravarIA: createAsyncAction(types.GRAVAR_IA),

  exibirUpload: (show, data) => createSyncAction(types.EXIBIR_UPLOAD, { show, data }),
};
