import basicFlow from "./asyncHandler";
import { delay, put, select, takeEvery } from "redux-saga/effects";
import { actions, types } from "../actions/documentos.actions";
import { authenticatedRequest, destinies } from "../utils/api";
import { fileToBase64, transformToDate } from "../utils/basic";
import { toast } from "react-toastify";
import { getIA, getUpload } from "../selectors/documentos.selectors";
import { getRandomValueBetween } from "../utils/mock";
import { actions as operacaoActions } from "../actions/operacao.actions";
import { routeWatcher } from "./rotas.saga";
import { getRouteArguments } from "../selectors/routes.selectors";
import { types as routes } from "../actions/rotas.actions";

const gravarValidadeCredito = basicFlow({
  actionGenerator: actions.gravarValidadeCredito,
  transform: function* ({
    id,
    operacao,
    files,
    validadeCredito,
    valorImovel,
    valorFinanciamento,
    financiaCustas,
    valorCustas,
    financiaIof,
    valorIof,
    financiaTarifaEmissao,
    valorTarifaEmissao,
    financiaTarifaRegistro,
    valorTarifaRegistro,
    tipoAmortizacao,
    valorPrimeiraParcela,
    taxaJuros,
    prazoDesejado,
    tipoRegra,
    valorBase,
  }) {
    return {
      id,
      files,
      operacao: operacao.id,
      validadeCredito: transformToDate(validadeCredito),
      valorImovel,
      valorFinanciamento,
      valorCustas,
      valorIof,
      valorTarifaEmissao,
      valorTarifaRegistro,
      taxaJuros,
      prazoDesejado,
      tipoRegra,
      valorBase,
      financiaCustas,
      financiaIof,
      financiaTarifaEmissao,
      financiaTarifaRegistro,
      valorPrimeiraParcela,
      tipoAmortizacao,
    };
  },
  api: ({ operacao, files, ...values }) => {
    return authenticatedRequest({
      url: `/operacao/${operacao}/validade-credito`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    const { id, files } = original;
    if(!!files){
      yield put(operacaoActions.prepararUploadDocumento.request({ id, files }));
    }

    if (!!original.callback) {
      original.callback();
    }
  },
});

const gravarCondicoesComerciais = basicFlow({
  actionGenerator: actions.gravarCondicoesComerciais,
  transform: function* ({
    id,
    operacao,
    files,
    valorImovel,
    valorFinanciamento,
    financiaCustas,
    valorCustas,
    financiaIof,
    valorIof,
    financiaTarifaEmissao,
    valorTarifaEmissao,
    financiaTarifaRegistro,
    valorTarifaRegistro,
    valorPrimeiraParcela,
    tipoAmortizacao,
    taxaJuros,
    prazoDesejado,
    tipoRegra,
    valorBase,
  }) {
    return {
      id,
      files,
      operacao: operacao.id,
      valorImovel,
      valorFinanciamento,
      valorCustas,
      valorIof,
      valorTarifaEmissao,
      valorTarifaRegistro,
      taxaJuros,
      prazoDesejado,
      tipoRegra,
      valorBase,
      financiaCustas,
      financiaIof,
      financiaTarifaEmissao,
      financiaTarifaRegistro,
      valorPrimeiraParcela,
      tipoAmortizacao,
    };
  },
  api: ({ operacao, files, ...values }) => {
    return authenticatedRequest({
      url: `/operacao/${operacao}/condicoes-comerciais`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    const { id, files } = original;
    yield put(operacaoActions.prepararUploadDocumento.request({ id, files }));

    if (!!original.callback) {
      original.callback();
    }
  },
});

const dominioOperacao = basicFlow({
  actionGenerator: actions.dominioOperacao,
  transform: ({ operacao }) => {
    return {
      operacao: operacao.id,
    };
  },
  api: ({ operacao }) => {
    return authenticatedRequest({
      url: `/operacao/${operacao}/dominios`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
});

const gravarLaudoAvaliacao = basicFlow({
  actionGenerator: actions.gravarLaudoAvaliacao,
  transform: function* ({
    id,
    operacao,
    files,
    dataLaudo,
    valorImovel,
    valorAvaliacao,
  }) {
    return {
      id,
      files,
      operacao: operacao.id,
      dataLaudo: transformToDate(dataLaudo),
      valorImovel,
      valorAvaliacao,
    };
  },
  api: ({ operacao, files, ...values }) => {
    return authenticatedRequest({
      url: `/operacao/${operacao}/laudo-avaliacao`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    const { id, files } = original;
    yield put(operacaoActions.prepararUploadDocumento.request({ id, files }));
    if (!!original.callback) {
      original.callback();
    }
  },
});

const permiteEnviarDocumentos = basicFlow({
  actionGenerator: actions.permiteEnviarDocumentos,
  api: ({ id, checklist, aberturaManual }) => {
    return authenticatedRequest({
      url: `/checklist-operacao/${id}/abertura-manual`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
      queryParams: {
        checklist,
        aberturaManual,
      },
    });
  },
  postSuccess: function* ({ original }) {
    toast.success("Alteração feita com sucesso!");
    if (!!original.callback) {
      original.callback();
    }
    yield put(operacaoActions.obterDocumentos.request({ id: original.id }));
  },
});

const permiteEnviarDocumento = basicFlow({
  actionGenerator: actions.permiteEnviarDocumento,
  api: ({ id, aberturaManual }) => {
    return authenticatedRequest({
      url: `/documento-operacao/${id}/abertura-manual`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
      queryParams: {
        aberturaManual,
      },
    });
  },
  postSuccess: function* ({ original }) {
    toast.success("Alteração feita com sucesso!");
    if (!!original.callback) {
      original.callback();
    }
    yield put(
      operacaoActions.obterDocumentos.request({ id: original.idOperacao })
    );
  },
});

const excluirDocumento = basicFlow({
  actionGenerator: actions.excluirDocumento,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/documento-operacao/${id}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "delete",
    });
  },
  postSuccess: function* ({ original }) {
    toast.success("Documento excluído com sucesso!");
    if (!!original.callback) {
      original.callback();
    }
    yield put(
      operacaoActions.obterDocumentos.request({ id: original.idOperacao })
    );
  },
});

const validarIA = basicFlow({
  actionGenerator: actions.validarIA,
  transform: function* () {
    const {
      data: { files },
    } = yield select(getIA);

    const file = files[0];
    return {
      success: file.name.toUpperCase().indexOf("OK") >= 0,
    };
  },
  api: ({ success }) => {
    return authenticatedRequest({
      url: `/ia`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "put",
      isMock: true,
      mockTimeout: getRandomValueBetween(6000, 10000),
      mockResult: !!success
        ? {
          success: true,
          text: 'O documento parece ser um registro de imóvel do Brasil, conhecido como "matrícula".\nNo topo, está indicado "OFICIAL DE REGISTRO DE IMÓVEIS", o que confirma a natureza do documento. A matrícula menciona um "IMÓVEL: TERRENO URBANO", e abaixo disso detalha informações sobre o terreno e a localização, mas não menciona explicitamente uma área construída, o que pode significar que é apenas um terreno sem construções.\nOs proprietários listados são "HERMINIO CORDEIRO" e "MARIA VENANCIO CORDEIRO". As assinaturas e selos presentes indicam atividades registrais, como transferência e averbação. Cada seção do documento refere-se a uma atividade diferente, como registro anterior, partilha e doação, com datas associadas a essas atividades.',
        }
        : {
          success: false,
          text: "O documento informado não parece ser uma matrícula.\nPor favor faça o upload com o arquivo correto.",
        },
    });
  },
});

const gravarIA = basicFlow({
  actionGenerator: actions.gravarIA,
  transform: function* () {
    const {
      data: { id, files },
    } = yield select(getIA);

    const arquivos = [];

    for (const f of files) {
      arquivos.push({
        base64: yield fileToBase64(f),
        nome: f.name,
      });
    }

    return {
      id,
      arquivos,
    };
  },
  api: ({ operacao, ...values }) => {
    return authenticatedRequest({
      url: `/documento-operacao/${values.id}`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "put",
      body: values,
    });
  },
  postSuccess: ({ original }) => {
    toast.success("Arquivo enviado com sucesso");
    if (!!original.callback) {
      original.callback();
    }
  },
});

const exibirValidacaoDocumento = basicFlow({
  actionGenerator: actions.exibirValidacaoDocumento,
  api: ({ operacao, documento }) => {
    return authenticatedRequest({
      url: `/operacao/${operacao}/documento/${documento}/validacao`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "get",
    });
  },
});

const gravarValidacaoDocumento = basicFlow({
  actionGenerator: actions.gravarValidacaoDocumento,
  api: ({ documentoOperacao }) => {
    return authenticatedRequest({
      url: `/operacao/${documentoOperacao?.idOperacao}/documento/${documentoOperacao?.id}/validar`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "put",
      body: {
        documentoOperacao,
      },
    });
  },
  postSuccess: function*({ original, response }) {
    if (!!original.callback && response.data.erros.length == 0) {
      yield put(actions.confirmarValidacaoDocumento.request({...original, statusAnalise: "AP"}))
    } else if (response.data.erros.length > 0) {
      toast.info("Foram encontradas inconsistências no documento. Verifique e tente novamente.");
    }
  }
});

const confirmarValidacaoDocumento = basicFlow({
  actionGenerator: actions.confirmarValidacaoDocumento,
  api: ({ documentoOperacao, statusAnalise }) => {
    return authenticatedRequest({
      url: `/operacao/${documentoOperacao?.idOperacao}/documento/${documentoOperacao?.id}/confirmar`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "put",
      body: {
        statusAnalise
      },
    });
  },
  postSuccess: ({ original }) => {
    toast.info("Análise confirmada com sucesso!");
    if (!!original.callback) {
      original.callback()
    }
  }
});

const obterRetornoAnalise = basicFlow({
  actionGenerator: actions.obterRetornoAnalise,
  api: ({ idOperacao, id }) => {
    return authenticatedRequest({
      url: `/documento-operacao/${id}/retorno-analise`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "GET",
    });
  },
  postSuccess: function* ({ original, response }) {
    const { show } = yield select(getIA);
    if (show && response?.data.situacao == "PE") {
      yield delay(10000);
      yield put(actions.obterRetornoAnalise.request(original));
    }
  }
});

function* exibirIaWatcher() {
  yield takeEvery(types.EXIBIR_IA, function* ({ payload }) {
    const { show, data } = payload;
    if (!!show) {
      yield put(actions.obterRetornoAnalise.request(data));
    }
  });
}
function* exibirCreditoWatcher() {
  yield takeEvery(
    [
      types.EXIBIR_CONDICOES_COMERCIAIS,
      types.EXIBIR_VALIDADE_CREDITO,
      types.EXIBIR_VALIDACAO_DOCUMENTO,
    ],
    function* ({ payload }) {
      const { show, data } = payload;

      if (!!show) {
        yield put(actions.dominioOperacao.request(data));
      }
    }
  );
}

function* validacaoDocumentoRouteWatcher() {
  yield routeWatcher([routes.VALIDACAO_DOCUMENTO], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.exibirValidacaoDocumento.request(payload));
  });
}

export const sagas = [
  gravarValidadeCredito.watcher(),
  gravarLaudoAvaliacao.watcher(),
  gravarCondicoesComerciais.watcher(),
  gravarIA.watcher(),
  obterRetornoAnalise.watcher(),
  validarIA.watcher(),
  dominioOperacao.watcher(),
  permiteEnviarDocumentos.watcher(),
  permiteEnviarDocumento.watcher(),
  excluirDocumento.watcher(),
  exibirIaWatcher(),
  exibirCreditoWatcher(),
  exibirValidacaoDocumento.watcher(),
  gravarValidacaoDocumento.watcher(),
  confirmarValidacaoDocumento.watcher(),
  validacaoDocumentoRouteWatcher(),
];
